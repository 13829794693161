import * as React from "react"
import Layout from "./layout"
import SEO from "./seo"
import Footer from "./home-footer"
import PostList from "./post-list"
import { Grid } from "./grid"
import Bio from './bio'
const rootPath = `${__PATH_PREFIX__}/`

const Posts = ({ location, posts, siteTitle, socialLinks }) => (
  <Layout location={location} title={siteTitle}>
    <SEO title="首页" />
    <Grid as="main" className="prose prose-light mb-24 dark:prose-dark">
    {location.pathname === rootPath && <Bio />}
      <PostList posts={posts} />
    </Grid>
   
    <Footer socialLinks={socialLinks} />
  </Layout>
)

const PostsWrapper = ({ location, data }) => {
  const { site, allBlogPost } = data || {};
  return (
    <Posts
      location={location}
      posts={allBlogPost.nodes.filter(node => node.slug !== '/about/')}
      siteTitle={site.siteMetadata.title}
      socialLinks={site.siteMetadata.social}
    />
  )
}

export default PostsWrapper
